import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Navbar from "../Navbar";
// import ScrollToTop from "../ScrollToTop";
import Footer from "../Footer";

function Home() {
  return (
    <section>
      <Navbar />
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 0 }} className="heading">
                {/* Hello,{" "} */}
                {/* <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span> */}
              </h1>

              {/* <h1 style={{ fontSize: 60 }} className="heading-name">
                WE ARE
                <strong className="main-name"> 223</strong>
              </h1> */}
              <div
                style={{
                  textAlign: "left",
                  fontSize: 85,
                  verticalAlign: "top",
                }}
              >
                WE ARE
                <strong className="main-name"> M✖SKIM</strong>
              </div>

              <div style={{ textAlign: "left", fontSize: 40 }}>
                <Type />
              </div>
            </Col>

            <Col
              md={5}
              style={{ paddingBottom: 100, paddingRight: 10, paddingTop: 30 }}
            >
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "850px" }}
              />
            </Col>
          </Row>
          <div
            style={{
              textAlign: "center",
              fontSize: 20,
              verticalAlign: "top",
              paddingBottom: 25,
            }}
          >
            The Seven Spirits are immortal and powerful demons and more
            precisely <strong className="main-name"> Gods</strong> as they hold
            deific power of certain phenomena as well as the ability to move
            distinctly. The invocations against the Seven describe their
            anthropomorphic manifestations as demons, which hold associations
            with the darker forces of nature. What is clear in the ancient texts
            is that the <strong className="main-name"> M✖✖✖✖✖</strong> are the
            highest class of demons, the same as Gods while they don't seek to
            empower the sheep of humanity. These Dark Gods inspire the strong
            who seek the depths of knowledge in which Ea is crown Prince over.
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          ></div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> first</strong> of the Seven is
            the South Wind: Anu declared 'When you band together and march out,
            you will have no rival'
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> second</strong> is a dragon with
            mouth agape that none can withstand. Anu decreed that it shall
            'ignite like Girra and blaze like a flame'
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> third</strong> is a grim leopard
            that carries off children. Anu gave this one the power 'to put on
            the face of a lion, so that anyone who sees him shall collapse in
            terror'
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> fourth</strong> is a terrible
            serpent and Anu commanded, 'the mountain flees before the one who
            bears your fierce weapons'
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> fifth</strong> is a furious wolf
            from which Anu proclaimed, 'blow like the wind and go forth to the
            rim of the earth'
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> sixth</strong> is a rebellious
            giant who submits neither to god nor king. Anu decreed that this one
            shall 'go above and below and spare none'
          </div>

          <div
            style={{ textAlign: "center", fontSize: 20, verticalAlign: "top" }}
          >
            The <strong className="main-name"> seventh</strong> is an evil
            windstorm (messenger of the fatal wind) from which Anu filled with
            dragon's venom to 'lay low living things'
          </div>
        </Container>
      </Container>
      <Home2 />
      <Footer />
    </section>
  );
}

export default Home;
